<template>
  <ReassuranceItem :has-background="!hasDeliveryBeforeChristmas">
    <template #icon>
      <RevIllustration
        v-if="hasDeliveryBeforeChristmas"
        alt=""
        :height="36"
        src="/img/product/delivery-before-christmas.svg"
        :width="36"
      />
      <IconTruckExpress v-else />
    </template>
    <template #content>
      <ShippingBlock :shipping="shipping" />
    </template>
  </ReassuranceItem>
</template>

<script lang="ts" setup>
import { type GetBestOffersResponse } from '@backmarket/http-api/src/api-specs-navigation-experience/product/best-offers'
import { RevIllustration } from '@ds/components/Illustration'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import { useDeliveryBeforeChristmas } from '~/scopes/product/composables/useDeliveryBeforeChristmas'

import ReassuranceItem from '../ReassuranceItem/ReassuranceItem.vue'

import ShippingBlock from './components/ShippingBlock/ShippingBlock.vue'

const props = defineProps<{
  shipping: GetBestOffersResponse[number]['reassurance']['shipping']
}>()

const { hasDeliveryBeforeChristmas } = useDeliveryBeforeChristmas(
  props.shipping,
)
</script>
